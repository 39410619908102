import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NovaColours } from '@novafuturltd/core'

export type DashboardStyleProps = {
  activeColour?: keyof typeof NovaColours.WidgetColours
  colour?: keyof typeof NovaColours.WidgetColours
  draggable?: boolean
  size?: {width: number, height: number}
}

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: NovaColours.AP060[theme.palette.mode],
  },
  menuDropdown: {
    marginTop: '37px',
  },
  formInput: {
    margin: theme.spacing(1.5, 0),
  },
  dialogActions: {
    margin: theme.spacing(0, 3, 0, 3),
    padding: '0',
  },
  colourPicker: {
    marginTop: theme.spacing(3),
  },
  colourCollection: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0, 3, 0),
  },
  colourDotContainer: {
    border: (props: DashboardStyleProps) =>
      props.activeColour === props.colour
        ? `4px solid ${NovaColours.AP060[theme.palette.mode]}`
        : '4px solid transparent',
    borderRadius: '100%',
  },
  colourDot: {
    position: 'relative',
    height: '46px',
    width: '46px',
    padding: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: (props: DashboardStyleProps) =>
      props.colour &&
      NovaColours.WidgetColours[props.colour][theme.palette.mode],
    border: `2px solid ${NovaColours.S010[theme.palette.mode]}`,
  },
  widgetCollection: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
  },
  icon: {
    fill: NovaColours.N010.light,
  },
  addWidgetButton: {
    position: (props: DashboardStyleProps) => props.size && props.size.width > 1100 ? 'fixed' : 'relative',
    right: (props: DashboardStyleProps) => props.size && props.size.width > 1100 ? 33 : 'auto',
    marginTop: (props: DashboardStyleProps) => props.size && props.size.width > 1100 ? -18 : 0,
  },
  addWidgetIcon: {
    position: (props: DashboardStyleProps) => props.size && props.size.width > 1100 ? 'fixed' : 'relative',
    right: (props: DashboardStyleProps) => props.size && props.size.width > 1100 ? 170 : 'auto',
    marginTop: (props: DashboardStyleProps) => props.size && props.size.width > 1100 ? -18 : 0,
  },
}))
