import { MenuItem } from '@mui/material'
import { NovaColours } from '@novafuturltd/core'
import { useNotifications } from '@novafuturltd/shared'
import { Modal } from '@novafuturltd/shared'
import { FormSelect } from '@novafuturltd/shared'
import { BasicTextFields } from '@novafuturltd/shared'
import React, { ChangeEvent, useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DashboardContext } from '../../containers/Dashboard/context'
import { useMixPanel } from '../../contexts/mixpanel'
import { ActionButton, CancelButton } from '@novafuturltd/shared'
import ColourPicker from './ColourPicker'
import { useStyles } from './styles'

interface IFormInput {
  url: string
  color: string
  display_name: string
  description: string
  new_window: number
}

interface Props {
  open: boolean
  link?: string
  headingText?: string
  handleClose: () => void
}

const CustomLinkModal: React.FC<Props> = ({
  open,
  handleClose,
  link,
  headingText,
}) => {
  const mxp = useMixPanel()
  const { addUserLink } = useContext(DashboardContext)
  const { Notification, notify } = useNotifications()
  const classes = useStyles({})
  const [widgetColour, setWidgetColour] = useState<
    keyof typeof NovaColours.WidgetColours
  >('ST050')
  const { control, errors, handleSubmit } = useForm<IFormInput>()

  const handleColourChange = (
    event: ChangeEvent<{ value: keyof typeof NovaColours.WidgetColours }>,
  ) => setWidgetColour(event.target.value)

  const onModalClose = () => {
    handleClose()
    setWidgetColour('ST050')
  }

  const createLink = async (values: IFormInput) => {
    const newWindow = values.new_window === 1 ? false : true
    try {
      const data = {
        ...values,
        new_window: newWindow,
        color: widgetColour,
      }
      if (link) {
        data.url = link
      }
      await addUserLink(data)
      mxp.track('Create Custom Link/Widget', {
        page: 'Dashboard',
        widgetCreated: link,
      })
      onModalClose()
    } catch (err) {
      notify.error('Unable to create new link.')
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onModalClose}
        fullWidth={true}
        maxWidth={'sm'}
        dialogHeading={headingText ? headingText : 'Add Link'}
        dialogContent={
          <form>
            <Controller
              as={
                <BasicTextFields
                  label="Link Name"
                  id="linkName"
                  size="medium"
                  variant="outlined"
                  fullWidth={true}
                  error={errors.display_name && true}
                  helperText={
                    errors.display_name && errors.display_name.message
                  }
                  className={classes.formInput}
                />
              }
              name="display_name"
              rules={{
                required: {
                  value: true,
                  message: 'Please input a link name',
                },
                pattern: {
                  value: /^[a-zA-Z0-9.,;:?!_\- ]{0,255}$/i,
                  message:
                    'Please input a valid name only containing the following symbols [a-zA-Z0-9.,;:?!_-]',
                },
                maxLength: {
                  value: 255,
                  message:
                    'Please input a link name smaller than 255 characters',
                },
              }}
              control={control}
              defaultValue=""
            />
            {!link && (
              <Controller
                as={
                  <BasicTextFields
                    label="Link e.g. https://www.novafutur.com/"
                    id="link"
                    size="medium"
                    variant="outlined"
                    fullWidth={true}
                    error={errors.url && true}
                    helperText={errors.url && errors.url.message}
                    className={classes.formInput}
                  />
                }
                name="url"
                rules={{
                  required: {
                    value: true,
                    message: 'Please input a url',
                  },
                  pattern: {
                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                    message: 'Please input a valid url',
                  },
                }}
                control={control}
                defaultValue=""
              />
            )}
            <Controller
              as={
                <BasicTextFields
                  label="Description"
                  id="description"
                  size="medium"
                  variant="outlined"
                  fullWidth={true}
                  error={errors.description && true}
                  helperText={errors.description && errors.description.message}
                  className={classes.formInput}
                />
              }
              name="description"
              rules={{
                required: {
                  value: true,
                  message: 'Please input a description for this link',
                },
                pattern: {
                  value: /^[a-zA-Z0-9.,;:?!_\- ]{0,255}$/i,
                  message:
                    'Please input a valid name only containing the following symbols [a-zA-Z0-9.,;:?!_-]',
                },

                maxLength: {
                  value: 255,
                  message:
                    'Please input a description smaller than 255 characters',
                },
              }}
              control={control}
              defaultValue=""
            />
            <FormSelect
              data-test="open-in"
              variant="outlined"
              name="new_window"
              label="Open in"
              control={control}
              defaultValue={0}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a location',
                },
              }}
              fullWidth={true}
              errors={errors.new_window}
              style={{ marginTop: '16px', minWidth: '200px' }}
            >
              <MenuItem key={0} value={0}>
                Open in new window
              </MenuItem>
              <MenuItem key={1} value={1}>
                Open in this window
              </MenuItem>
            </FormSelect>
            <ColourPicker
              widgetColour={widgetColour}
              handleColourChange={handleColourChange}
            />
          </form>
        }
        dialogActions={
          <>
            <CancelButton onClick={onModalClose}></CancelButton>
            <ActionButton
              onClick={handleSubmit(createLink)}
              text="Add Link to Dashboard"
            ></ActionButton>
          </>
        }
      />
      <Notification />
    </>
  )
}
export default CustomLinkModal
