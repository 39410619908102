// @todo sort out with common dependencies
// as it all same move to shared lib
import qs from 'qs'
// @todo external
import client from '../../../../api/client'
// @todo external
import { k8BaseUrl, services } from '../../../../config/index'

export default {
  get: (url: string, params = {}, headers = {}) => {
    return client({
      method: 'GET',
      url,
      baseURL: `${k8BaseUrl}${services.vision}`,
      params,
      headers,
      paramsSerializer: p => {
        return qs.stringify(p, {
          encodeValuesOnly: true,
        })
      },
    })
  },
}
