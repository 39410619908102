import { useOAuth } from '@novafuturltd/core'
import { Notify } from '@novafuturltd/shared'
import { permissionValidation } from '@novafuturltd/shared'
import { createContext, useContext, useEffect, useState } from 'react'
import { Layout, Layouts } from 'react-grid-layout'
import { useMutation, useQuery } from 'react-query'
import {
  createLayout,
  createLink,
  deleteLink,
  getLayout,
  getLinks,
  updateLayout,
} from '../../../api/nHub/userPreference'
import { fetchGeneralStats } from '../../../modules/simulate/api/reports/reports'

export interface IUserLink {
  link_id: string
  org_id: string
  user_id: string
  color: string
  display_name: string
  description: string
  new_window: boolean
  url: string
}

export interface IFormInput {
  url: string
  color: string
  display_name: string
  description: string
  new_window: boolean
}

interface IDashboardContext {
  addUserLink: (body: IFormInput) => void
  data: IUserLink[] | null
  deleteUserLink: (id: string) => void
  layouts: Layouts | {}
  isLayoutLoading: boolean
  handleLayoutChange: (_: Layout[], allLayouts: Layouts) => void
  saveLayout: () => void
  simulateReportsStats: any
  simulateReportsStatsLoading: boolean
  isLinksLoading: boolean
}

export const DashboardContext = createContext<IDashboardContext>({
  addUserLink: () => {},
  data: [],
  deleteUserLink: () => {},
  layouts: {},
  isLayoutLoading: true,
  isLinksLoading: false,
  handleLayoutChange: () => {},
  saveLayout: () => {},
  simulateReportsStatsLoading: false,
  simulateReportsStats: undefined,
})

export const useDashboard = () => useContext(DashboardContext)

const defaultLayout = {
  lg: [
    { i: 'users-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'invites-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'create-csv-campaign', x: 0, y: 0, w: 4, h: 3 },
    { i: 'create-login-campaign', x: 0, y: 0, w: 4, h: 3 },
    { i: 'service-desk-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'simulate-reports-widget', x: 1, y: 1, w: 4, h: 3 },
  ],
  md: [
    { i: 'users-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'invites-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'create-csv-campaign', x: 0, y: 0, w: 4, h: 3 },
    { i: 'create-login-campaign', x: 0, y: 0, w: 4, h: 3 },
    { i: 'service-desk-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'simulate-reports-widget', x: 1, y: 1, w: 4, h: 3 },
  ],
  sm: [
    { i: 'users-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'invites-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'create-csv-campaign', x: 0, y: 0, w: 4, h: 3 },
    { i: 'create-login-campaign', x: 0, y: 0, w: 4, h: 3 },
    { i: 'service-desk-widget', x: 0, y: 0, w: 4, h: 3 },
    { i: 'simulate-reports-widget', x: 1, y: 1, w: 4, h: 3 },
  ],
}

export const DashboardProvider = (props: {
  page?: string
  notify: Notify
  children: React.ReactNode
}) => {
  const { permissions } = useOAuth()

  const {
    data: links,
    refetch: refetchLinks,
    isError: isLinkError,
    isLoading: isLinksLoading,
  } = useQuery(['userPrefLinks'], getLinks, {
    refetchOnWindowFocus: false,
    enabled: props.page === 'dashboard',
  })

  const {
    data: userLayout,
    refetch: refetchLayout,
    isLoading: isLayoutLoading,
    isError: isLayoutError,
  } = useQuery(['userPrefLayout'], getLayout, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: props.page === 'dashboard',
  })

  const [configId, setConfigId] = useState(null)
  const [layouts, setLayouts] = useState<Layouts>(defaultLayout)

  const {
    data: simulateReportsStats,
    isLoading: simulateReportsStatsLoading,
  } = useQuery(
    ['simulateReportsGeneralData'],
    () =>
      fetchGeneralStats({
        start_date: new Date().toISOString().substring(0, 10),
        end_date: new Date().toISOString().substring(0, 10),
      }),
    {
      refetchOnWindowFocus: true,
      retry: false,
      enabled:
        permissionValidation(permissions, [
          'simulate_reports.general_stats.get',
        ]) && props.page === 'dashboard',
    },
  )

  const { mutate: createUserLayout } = useMutation(
    () => createLayout({ json: layouts }),
    {
      onSuccess: () => {
        props.notify.success('Layout successfully created')
        refetchLayout()
      },
      onError: () => {
        props.notify.error('Layout could not be created')
      },
    },
  )

  const { mutate: updateUserLayout } = useMutation(
    () => updateLayout(configId, { json: layouts }),
    {
      onSuccess: () => {
        props.notify.success('Layout successfully updated')
        // tslint:disable-next-line: no-unused-expression
        props.page === 'dashboard' && refetchLayout()
      },
      onError: () => {
        props.notify.error('Layout could not be updated')
      },
    },
  )

  const handleLayoutChange = (_: Layout[], allLayouts: Layouts) =>
    setLayouts(allLayouts)

  const saveLayout = () => {
    if (configId) {
      return updateUserLayout()
    }
    return createUserLayout()
  }

  const { mutate: addUserLink } = useMutation(
    (body: IFormInput) => createLink(body),
    {
      onSuccess: response => {
        props.notify.success('New link successfully created.')
        setLayouts({
          ...layouts,
          lg: [
            ...layouts.lg,
            { w: 4, h: 3, i: response.data.data.link_id, x: 0, y: 12 },
          ],
          md: [
            ...layouts.md,
            { w: 4, h: 3, i: response.data.data.link_id, x: 0, y: 12 },
          ],
          sm: [
            ...layouts.sm,
            { w: 4, h: 3, i: response.data.data.link_id, x: 0, y: 12 },
          ],
        })
        saveLayout()
        // tslint:disable-next-line: no-unused-expression
        props.page === 'dashboard' && refetchLinks()
      },
      onError: () => {
        props.notify.error('Unable to create new link.')
      },
    },
  )

  const { mutate: deleteUserLink } = useMutation(
    (id: string) => deleteLink(id),
    {
      onSuccess: (_, variables) => {
        props.notify.success('Link successfully deleted')
        setLayouts({
          ...layouts,
          lg: layouts.lg.filter(layout => layout.i !== variables),
          md: layouts.md.filter(layout => layout.i !== variables),
          sm: layouts.sm.filter(layout => layout.i !== variables),
        })
        saveLayout()
        refetchLinks()
      },
      onError: () => {
        props.notify.error('Unable to delete link')
      },
    },
  )

  useEffect(() => {
    if (!isLayoutLoading) {
      if (userLayout?.data.data.json) {
        setLayouts(userLayout?.data.data.json)
        setConfigId(userLayout?.data.data.config_id)
      }
    }
  }, [isLayoutLoading])

  useEffect(() => {
    if (isLinkError && isLayoutError) {
      return props.notify.error('There was an error loading your data')
    }
    if (isLinkError) {
      return props.notify.error('There was an error loading your links')
    }
    if (isLayoutError) {
      return props.notify.error('There was an error loading your layout')
    }
  }, [links, userLayout])

  return (
    <DashboardContext.Provider
      value={{
        addUserLink,
        data: links?.data?.data,
        isLayoutLoading,
        isLinksLoading,
        deleteUserLink,
        layouts,
        handleLayoutChange,
        saveLayout,
        simulateReportsStatsLoading,
        simulateReportsStats: simulateReportsStats?.data?.data.data,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  )
}
