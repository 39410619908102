import { ButtonBase, Tooltip } from '@mui/material'
import { DashboardStyleProps, useStyles } from './styles'

interface Props {
  colourName: string
  handleClick: (event: any) => void
  props?: any
}

const ColourDot: React.FC<Props & DashboardStyleProps> = ({
  colour,
  colourName,
  activeColour,
  handleClick,
  props,
}) => {
  const classes = useStyles({ colour, activeColour })

  return (
    <Tooltip title={colourName}>
      <div className={classes.colourDotContainer}>
        <ButtonBase
          className={classes.colourDot}
          value={colour}
          onClick={handleClick}
          {...props}
        />
      </div>
    </Tooltip>
  )
}

export default ColourDot
