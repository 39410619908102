import { NovaColours } from '@novafuturltd/core'
import { Heading } from '@novafuturltd/shared'
import ColourDot from './ColourDot'
import { useStyles } from './styles'
import { widgetColours } from './widgetColours'

interface Props {
  widgetColour: keyof typeof NovaColours.WidgetColours
  handleColourChange: (event: any) => void
}

const ColourPicker: React.FC<Props> = ({
  widgetColour,
  handleColourChange,
}) => {
  const classes = useStyles({})

  return (
    <div className={classes.colourPicker}>
      <Heading variant="body1">Choose a widget colour</Heading>
      <div className={classes.colourCollection}>
        {widgetColours.map((colourDot: any, index: number) => {
          return (
            <ColourDot
              colour={colourDot.colourCode}
              colourName={colourDot.displayName}
              activeColour={widgetColour}
              handleClick={handleColourChange}
              key={index}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ColourPicker
