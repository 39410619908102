import client from './index'

interface ISimulateReportApiParams {
  start_date?: string
  end_date?: string
  user_type?: string
  source?: string
  free_bet?: undefined | boolean
}

interface ISimulateReportApiMeta {
  limit: number
  page_number: number
  order_by: string
}

export const fetchGeneralStats = ({
  start_date,
  end_date,
  user_type,
  source,
  free_bet,
}: ISimulateReportApiParams) =>
  client.get('/simulate/general_stats', {
    start_date,
    end_date,
    user_type,
    source,
    free_bet,
  })

export const fetchCharts = ({
  start_date,
  end_date,
  user_type,
  source,
  free_bet,
}: ISimulateReportApiParams) =>
  client.get('/simulate/charts', {
    start_date,
    end_date,
    user_type,
    source,
    free_bet,
  })

export const fetchMarketStats = ({
  limit,
  page_number,
  order_by,
  start_date,
  end_date,
  user_type,
  source,
  free_bet,
}: ISimulateReportApiParams & ISimulateReportApiMeta) =>
  client.get('/simulate/market_stats', {
    limit,
    page_number,
    order_by,
    start_date,
    end_date,
    user_type,
    source,
    free_bet,
  })

export const fetchEventStats = ({
  limit,
  page_number,
  order_by,
  start_date,
  end_date,
  user_type,
  source,
  free_bet,
}: ISimulateReportApiParams & ISimulateReportApiMeta) =>
  client.get('/simulate/event_stats', {
    limit,
    page_number,
    order_by,
    start_date,
    end_date,
    user_type,
    source,
    free_bet,
  })
