export const widgetColours = [
  {
    displayName: 'Turquoise',
    colourCode: 'ST050',
  },
  {
    displayName: 'Lime Green',
    colourCode: 'SLG050',
  },
  {
    displayName: 'Acid Green',
    colourCode: 'SAG050',
  },
  {
    displayName: 'Purple',
    colourCode: 'SPP050',
  },
  {
    displayName: 'Blue',
    colourCode: 'SB050',
  },
  {
    displayName: 'Sky Blue',
    colourCode: 'SSB050',
  },
  {
    displayName: 'Pink',
    colourCode: 'SP050',
  },
  {
    displayName: 'Coral Red',
    colourCode: 'SCR050',
  },
  {
    displayName: 'Yellow',
    colourCode: 'SY050',
  },
] as const
